import Vue from "vue";

import Vuex from "vuex";

Vue.use(Vuex);

const state = () => ({
  type:'success',
  text:'',
  show:false
});

const getters = {
  alert: (state) => {
    return state.name;
  },
};


const mutations = {
  getAlert(state, alert) {
    state.type = alert.type
    state.text = alert.text
    state.show = true
    setTimeout(() => {
      state.show= false
    }, 6000);
    
  },
  closeAlert(state){
    state.show=false
  }
};

const actions = {
  getAlert(context, alert) {
    context.commit("getAlert", alert);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
