<template>
    <div v-if="user.isAuthenticated">
        <v-navigation-drawer
      app
      :mini-variant="mini"
      permanent
    >
     <v-list-item class="px-2" style="height:64px">
        <v-list-item-avatar v-if="!mini" color="principal">
          <span class="white--text text-h5" >{{avatar}}</span>
        </v-list-item-avatar>
         <v-btn
          icon
          @click.stop="mini = !mini"
          v-if="mini"
          size="1.5rem"
        >
          <v-icon>chevron_right</v-icon>
        </v-btn>
        <v-list-item-title >{{user.username}}</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>chevron_left</v-icon>
        </v-btn>
      </v-list-item>
      
      
      <v-divider></v-divider>

      
        <v-list-item
          link
          to="/"
        >
          <v-list-item-icon>
            <v-icon>analytics</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>panel</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="'/'+item.path"
          v-if="item.permission.includes(user.role)"
        >
          <v-list-item-icon >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    name:'Drawer',
    
    data(){
      return{
        mini:true,
        drawer:true,
        items:[
          { title: 'configuraciones', icon: 'settings', permission: ['admin'], path:'settings'},
          { title: 'estadisticas', icon: 'bar_chart', permission: ['admin'], path:'statistics'},
          { title: 'pedidos', icon: 'shopping_bag', permission: ['admin','seller'], path:'orders' },
          { title: 'productos', icon: 'inventory', permission: ['admin'], path:'products' },
          {title:'vender',icon:'shopping_cart', permission: ['admin','seller' ], path:'sell'}        
        ]

      }
    },
    computed:{
    ...mapState({
            user: state => state.userModule
        }),
      
    avatar(){
      return this.user.username.split('')[0]
    }
    
    }
    
  }
</script>

<style lang="scss" scoped>

</style>