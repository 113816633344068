import Vue from "vue";
import createPersistedState from 'vuex-persistedstate';
import Vuex from "vuex";
import Cookies from "js-cookie";
import cartModule, {state} from "./cartModule";
import userModule from "./userModule";
import companyModule from "./companyModule"
import alertModule from "./alertModule";
import printerConfig from "./printerConfig";

Vue.use(Vuex);

const vuexCookie = createPersistedState({
  paths: [
    'userModule.isAuthenticated',
    'userModule.token',
    'userModule.username',
    'userModule.role'
  ],
  storage: {
    getItem: key => Cookies.get(key),
    setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
    removeItem: key => Cookies.remove(key)
  }
})


const vuexLocal = createPersistedState({
  paths: [
    'cartModule.nombre',
    'cartModule.entrega',
    'cartModule.pago_metodo',
    'cartModule.descuento',
    'cartModule.amount_discount',
    'cartModule.pedido',
    'cartModule.telefono',
    'companyModule.name',
    'companyModule.image',
    'companyModule.phone',
    'companyModule.address',
    'printerConfig.url'
  ]
})

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    userModule,
    cartModule,
    companyModule,
    alertModule,
    printerConfig
  },
  plugins: [vuexCookie, vuexLocal]
});


export default store