<template>
  <v-app>
    <AppBar />
    <Drawer />

    <v-main class="grey lighten-3">
      <router-view />
      <v-alert
      :value="alert.show"
      dense
      :type="alert.type"
      class="custom-alert"
    >
      <div class="d-flex justify-sm-space-between align-center">
        {{alert.text}}
        <v-btn
              icon
              color="white"
              @click="closeAlert"
            >
              <v-icon>cancel</v-icon>
            </v-btn>
      </div>
    </v-alert>

    </v-main>
    <v-btn v-show="showScroll" color="blue" @click="scrollToTop" fab dark small fixed bottom right>
      <v-icon>expand_less</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import Drawer from "./components/Drawer";
import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  components: { AppBar, Drawer },
  data: () => ({
    //
    showScroll:false
  }),
  mounted() {
    window.addEventListener("scroll", this.onScroll)
    let oldLink = document.querySelector("link[rel*='icon']");
    oldLink.href = this.company.image;
  },
  computed: {
    ...mapState({
      user: (state) => state.userModule,
      company: (state) => state.companyModule,
      alert: (state)=> state.alertModule
    }),
    
  },
  methods: {
    ...mapMutations('alertModule',['closeAlert']),
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    onScroll(e){
      this.showScroll = e.target.documentElement.scrollTop > 0}
  },
};
</script>

<style lang="scss">
@import "src/scss/index.scss";
</style>
