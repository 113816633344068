<template>
  <div>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">

      <v-toolbar-title>{{company.name}}</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
    <v-btn text v-if="user.isAuthenticated" @click="logOutButton">
      salir
      <v-icon right >
          logout
        </v-icon>
    </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { apiFetch } from '@/axios/axiosInstance'
import { mapActions, mapState } from 'vuex'


  export default {
    name:'AppBar',

    mounted(){
 
      this.company.name.length === 0 && this.getCompanyFetch()

    },
    computed:{
      ...mapState({
        user : state => state.userModule,
        company: state => state.companyModule
      })
    },
    methods:{
      ...mapActions('userModule',['logOut']),
      ...mapActions('companyModule',['getCompany']),
      async getCompanyFetch(){
        try{
          const response = await apiFetch.get('/company-name/')
          const company = response.data[0]
          this.getCompany({
            name:company.name,
            image:company.logo,
            phone:company.phone_number,
            address:company.address
          })

        }catch(err){
          console.log(err);
        }
      },
      async logOutButton(){
        try{
            const tokenLogOut = {token :this.user.token}
            //const response = await apiFetch.post('/logout/', tokenLogOut)
            this.logOut()
            this.$router.push('/login')
        }catch(err){
          console.log(err.response.data);
        }

      }

    },

    
  }
</script>

<style lang="scss" scoped>

</style>