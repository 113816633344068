import Vue from "vue";

import Vuex from "vuex";

Vue.use(Vuex);

const state =() => ({
    isAuthenticated:false,
    username:'',
    token:'',
    role:''
})

const getters = {
  user:(state)=>{
    return state.username;
  }
}

const mutations = {
  logIn(state,user){
    state.username = user.username;
    state.isAuthenticated = user.authenticated
    state.token = user.token
    state.role = user.role
  },
  logOut(state){
    state.username = "";
    state.isAuthenticated = false
    state.token = "",
    state.role = ""
  },
  refreshToken(state, token){
    state.token= token.token
  }
}

const actions = {
  logIn(context,user){
    context.commit('logIn',user)
    
  },
  logOut(context){
    context.commit('logOut')
  },
  refreshToken(context,token){
    context.commit('refreshToken', token)
  }
}


export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}