import Vue from "vue";

import Vuex from "vuex";

Vue.use(Vuex);

const state = () => ({
  name: "",
  image: "",
  phone: "",
  address: ""
});

const getters = {
  company: (state) => {
    return {
      name: state.name,
      image: state.image,
      phone: state.phone,
      address: state.address,
    };
  },
};

const mutations = {
  getCompany(state, company) {
    state.name = company.name;
    state.image = company.image;
    state.phone = company.phone;
    state.address = company.address;
  },
};

const actions = {
  getCompany(context, company) {
    context.commit("getCompany", company);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
