import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = () => ({
  
    nombre:'local',
    entrega:'local',
    pago_metodo:'-',
    descuento: 0,
    amount_discount: 0,
    pedido: [],
    telefono:'-'
  
})

const mutations = {
  addQuantityState(state,item){
    let indexDesserts = state.pedido.findIndex(it => it.producto_id.id === item.id);
    state.pedido[indexDesserts].quantity++
  },
  removeQuantityState(state, item){
    let indexDesserts = state.pedido.findIndex(it => it.producto_id.id === item.id);
    state.pedido[indexDesserts].quantity--
  },
  addItemState(state, item) {
    state.pedido.push(item);
  },
  removeItem(state,idx){
    state.pedido.splice(idx,1)
  },
  emptyCart(state){
    state.nombre='local',
    state.entrega='local',
    state.pago_metodo='-',
    state.descuento= 0,
    state.amount_discount= 0,
    state.pedido= [],
    state.telefono='-'
    
  },
  saveState(){},
}

export default {
  namespaced : true,
  state,
  mutations

}