import Vue from "vue";

import Vuex from "vuex";

Vue.use(Vuex);

const state = () => ({
  url: ""
});

const getters = {
  printer: (state) => {
    return state.url;
  },
};

const mutations = {
  setPrinterUrl(state, printer) {
    state.url = printer.url;
  },
};

const actions = {
  getPrinter(context, printer) {
    context.commit("getPrinter", printer);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
