import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'



Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '../views/StatisticsView.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/OrdersView.vue')
  },
  {
    path: '/orderEdit/:id',
    name: 'orderEdit',
    component: () => import(/* webpackChunkName: "orderEdit" */ '../views/OrderEditView.vue')
  },
  {
    path: '/Sell',
    name: 'Sell',
    component: () => import(/* webpackChunkName: "SellView" */ '../views/SellView.vue')
  },
  {
    path: '/products/history/:id',
    name: 'productHistoryView',
    component: () => import(/* webpackChunkName: "404" */ '../views/productHistory.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404View.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if(to.name !== 'login' && !store.state.userModule.isAuthenticated) return next('login');
  else next()
})

export default router
