import router from "@/router";
import axios from "axios";
import Swal from "sweetalert2";
import store from '../store/index'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

const baseURL = process.env.NODE_ENV === 'development' ? 'http://192.168.100.244:8000/api' : 'https://milananails.com/api'

const apiFetchWithoutToken = axios.create({baseURL})


const apiFetch = axios.create({baseURL: baseURL})
const apiFetchMultiPart = axios.create({baseURL: baseURL})
const refreshAccessToken = () => axios.get(`${baseURL}/refresh-token/?username=${store.state.userModule.username}`).then(response => response.data)


apiFetchMultiPart.interceptors.request.use(
  async config => {
    config.headers = { 
      'Authorization': `Bearer ${store.state.userModule.token}`,
      'Content-Type': 'multipart/form-data'
    }
    return config;
  },
  error => {
    Promise.reject(error)
});



apiFetch.interceptors.request.use(
  async config => {
    config.headers = { 
      'Authorization': `Bearer ${store.state.userModule.token}`
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
apiFetch.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await refreshAccessToken();  
    store.commit('userModule/refreshToken',access_token)          
    return apiFetch(originalRequest);
    /* if(error.response.data.expired){
    }else{
      store.commit('userModule/logOut')
      router.push('/login')
      Swal.fire({
        position: 'center',
        icon: 'error',
        iconColor:'#FF5252',
        title: 'alguien ha iniciado sesion desde otro dispositivo',
        showConfirmButton: true,
        confirmButtonColor: '#007BFF'
      })
    } */
    
  }
  return Promise.reject(error);
});



export { apiFetch,
        apiFetchWithoutToken,
        apiFetchMultiPart };